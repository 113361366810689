<template>
  <div class="all-height">
    <floadingPageHeader icon="mdi-cog" :loading="loading">
      <template v-slot:content>

        <div class="all-width">
          <div v-if="Object.keys(engagement).length === 0" class="d-flex all-height align-center justify-center">
            <div>
              <div class="mb-2 title">No engagement found!</div>
              <v-btn color="primary" @click="$nova.gotoLink({path: '/ia/engagement'})"><v-icon left>mdi-arrow-left</v-icon> Go back to Engagement List</v-btn>
            </div>
          </div>
          <div v-else>
            <v-card class="">
                <v-card-text class="pt-0">
                  <div>
                    <div class="subtitle-1 mb-2">
                      <strong>Subscription Period:</strong> {{$nova.formatDate(engagement.from)}} - {{$nova.formatDate(engagement.to)}}
                    </div>
                    <v-row>
                      <v-col class="pr-0">
                        <div class="d-flex align-center">
                          <v-divider></v-divider>
                          <v-avatar size="26" :color="(status.risk && status.location)?'success':'info'"><strong class="white--text">1</strong></v-avatar>
                          <v-divider></v-divider>
                        </div>
                        <div class="text-center mt-4">
                          <div class="pb-0 subtitle-2" >
                            <strong>Client Setup</strong>
                          </div>
                          <div class="d-flex aligh-center">
                            <v-spacer></v-spacer>
                            <div class="pb-4 pt-0 text-center">
                              <div class="mt-1" >
                                <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/engagement/setup/'+id, hash: '#risk'})">
                                  <v-icon v-if="status.risk" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                  <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                  Risk Info 
                                </v-btn>
                              </div>
                              <div class="mt-1" >
                                <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/engagement/setup/'+id, hash: '#location'})">
                                  <v-icon v-if="status.location" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                  <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                  Multiple Locations 
                                </v-btn>
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="pl-0">
                        <div class="d-flex align-center">
                          <v-divider></v-divider>
                          <v-avatar size="26" :color="(status.scope)?'success':'info'"><strong class="white--text">2</strong></v-avatar>
                          <v-divider></v-divider>
                        </div>
                        <div class="text-center mt-4">
                          <div class="pb-0 subtitle-2" >
                            <strong>Engagement Setup</strong>
                          </div>
                          <div class="d-flex aligh-center">
                            <v-spacer></v-spacer>
                            <div class="pb-4 pt-0 text-center">
                              <div class="mt-1" >
                                <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/engagement/setup/'+id, hash: '#scope'})">
                                  <v-icon v-if="status.scope" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                  <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                  Scope Mapping
                                </v-btn>
                              </div>
                              <div class="mt-1" >
                                <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/engagement/setup/'+id, hash: '#contacts'})">
                                  <v-icon v-if="status.contact" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                  <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                  Contact List 
                                </v-btn>
                              </div>
                              <div class="mt-1" >
                                <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/engagement/setup/'+id, hash: '#fileconfig'})">
                                  <v-icon v-if="status.scope" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                  <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                  File Config
                                </v-btn>
                              </div>
                              <div class="mt-1" >
                                <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/engagement/setup/'+id, hash: '#inputparameterconfig'})">
                                  <v-icon v-if="status.scope" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                  <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                  Input Parameter
                                </v-btn>
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
            </v-card>

            <v-card class="shadow2-a">
              <v-dialog v-model="assignmentdialog" max-width="600">
                <v-card :loading="assignmentloading" :disabled="assignmentloading" loader-height="2" class="">
                  <v-card-text class="primary white--text d-flex align-center py-2 px-4">
                    <span class="subtitle-1 white--text">Add New Assignment</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="assignmentdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
                  </v-card-text>
                  <v-card-text class="mt-4 pt-4">
                    <v-row>
                      <v-col cols="12" class="my-0 py-1">
                        <lb-dropdown :items="locationlist" itemvalue="_id" itemtext="name" v-model="addassignmentdata.location" label="Location"  :error="addassignmentdataerror.location"/>
                      </v-col>
                      <v-col cols="12" class="my-0 py-1">
                        <lb-dropdown :items="scopelist" itemvalue="_id" itemtext="name" v-model="addassignmentdata.scope" label="Scope" chips multiple  :error="addassignmentdataerror.scope"/>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <lb-date v-model="addassignmentdata.from" label="Period Start Date" :error="addassignmentdataerror.from"/>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <lb-date v-model="addassignmentdata.to" label="Period End Date" :error="addassignmentdataerror.to"/>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn v-if="addassignmentdata._id" small color="primary" @click="addEditAssignment('edit')">Update Assignment</v-btn>
                    <v-btn v-else small color="primary" @click="addEditAssignment('add')">Add Assignment</v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </div>
        </div>

      </template>
    </floadingPageHeader>

  </div>
</template>

<script>
// @ is an alias to /src
import floadingPageHeader from '@/views/common/FloatingPageHeader'

export default {
  name: 'engagement_progress',
  // props: ['props'],
  data: function(){
    return {
      id: "",
      loading: true,
      engagement: {},
      status: {risk: false, location: false, scope: false},
      assignmentdialog: false,
      assignmentloading: false,
      locationlist: [],
      scopelist: [],
      addassignmentdata: {},
      addassignmentdataerror: {},
      assignmentlist: [],
    }
  },
  components: {
    floadingPageHeader
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      this.loading = true;
      this.engagement = {};
      this.status = {risk: false, location: false, scope: false};
      this.assignmentdialog = false;
      this.assignmentloading = false;
      this.locationlist = [];
      this.scopelist = [];
      this.addassignmentdata = {};
      this.addassignmentdataerror = {};
      this.getData();
     
    },
    getData(){
      this.errors = {};
      this.loading = true;
      this.engagement = {};
      this.axios.post("/v2/ia/engagement/getprogress").then(dt => {
       
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.engagement = dt.data.data[0].engagement ?? {};
          
          if((this.engagement.engagement_setup ?? {}).riskinfo) this.status.risk = true;
          if((this.engagement.engagement_setup ?? {}).location || !((this.engagement.engagement_setup ?? {}).riskinfo || {}).multilocation) this.status.location = true;
          if((this.engagement.engagement_setup ?? {}).scope) this.status.scope = true;
          return this.axios.post("/v2/ia/assignment/setupformselects");
        }else throw new Error (dt.data.message || "Unknown error!");
      }).then(dt => {
        if(dt.data.status === "success"){
          this.locationlist = (dt.data.data[0] ?? {}).location;
          this.scopelist = (dt.data.data[0] ?? {}).scope;
        }else throw new Error ("Error fetching assignment details");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addEditAssignment(t = "add"){
      this.assignmentloading = true;
      this.addassignmentdataerror = {};
      Promise.resolve().then(() => {
        let url = "/v2/ia/assignment/add/";
        if(t === "edit") url = "/v2/ia/assignment/edit/";
        return this.axios.post(url+this.id, {data: this.addassignmentdata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "New Assignment added");
          this.assignmentdialog = false;
          this.refreshData();
        }else {
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addassignmentdataerror = dt.data.data[0].index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.assignmentloading = false;
      })
    },
  },
  computed: {
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    }
  }
}
</script>
