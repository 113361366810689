<template>
  <div class="float-container">
    <div class="icon-holder d-flex align-center px-10">
      <v-card class="primary shadow-on mb-8">
        <v-card-text>
          <v-avatar size="70">
            <v-progress-circular v-if="loading" indeterminate color="white"></v-progress-circular>
            <v-icon v-else class="display-3 white--text">{{icon}}</v-icon>
          </v-avatar>
        </v-card-text>
      </v-card>
      <div class="pl-4 my-8">
        <span class="title font-weight-bold">{{title}}</span>
      </div>
    </div>
    <v-card class="pa-0 content-holder" :disabled="loading">
      <v-card-text class="pa-0">
        <slot name="content"></slot>
        
      </v-card-text>
    </v-card>
    <div class="bg-holder radius-all white shadow-on">
    </div>
  </div>

</template>

<script>
  export default {
    name: 'floadingpageheader',
    components: {
    },
    methods: {
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style scoped>
.float-container{
  display: grid;
  /* grid-template-columns: auto auto auto auto; */
}
.icon-holder {
  grid-area: 1 / 1 / span 4 / span 1;
}
.bg-holder {
  content: '';
  z-index: -1;
  grid-area: 2 / 1 / span 4 / span 1;
}
.content-holder {
  grid-area: 5 / 1 / span 1 / span 1;
}

</style>
